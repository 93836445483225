import { useTranslation } from 'next-i18next';
import { Container } from 'react-bootstrap';

import { Link } from 'react-scroll';


const Header = () => {
  const { t } = useTranslation();

  return (
    <section id="hero" className="jumbotron">
      <Container>
          <h1 className="hero-title">
            {` ${t('home.title')}`}
            <span className="text-color-main">{t('home.name')}</span>
            <br />
            {t('home.subtitle')}
          </h1>

          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000} title={t('home.cta')}>
                {t('home.cta')}
              </Link>
            </span>
          </p>
      </Container>
    </section>
  );
};

export default Header;
