import { GetServerSideProps } from 'next';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Hero from 'components/Hero';
import About from 'components/About';
import Projects from 'components/Projects';
import Contact from 'components/Contact';
import Footer from 'components/Footer';

export const Home = () =>  (
  <>
    <Hero />
    <About />
    <Projects />
    <Contact />
    <Footer />
  </>
);


export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = context;
  
  return {
    props: {
      ...(await serverSideTranslations(locale, ['default'])),
    },
  };
};

export default Home;