import { GetServerSideProps } from 'next';
import { SocialProfileJsonLd } from 'next-seo'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Home } from 'widgets/Home'

const HomePage = () =>(
    <>
    <SocialProfileJsonLd
      type="Person"
      address="Dubai, UAE"
      homeLocation="Dubai, UAE"
      workLocation="Dubai, UAE"
      affiliation="MoscowJS"
      name="Dmitrii Shmakov"
      email="to.dmitri.shmakov@gmail.com"
      jobTitle="Lead Software Engineer"
      nationality="Russia"
      url="https://shmakovdima.ru"
      alumniOf="High School of Economics"
      image="https://shmakovdima.ru/og-image.jpg"
      birthPlace="Moscow, Russia"
      birthDate="1993-05-24"
      telephone="+971 58 456-7890"
      gender="male"
      knowsAbout="MoscowJS, React, Typescript"
      knowsLanguage="English, Russian, Deutsch"
      sameAs={[
        'https://www.facebook.com/shmakovdima',
        'https://www.twitter.com/shmakovdima',
        'https://www.linkedin.com/in/shmakovdima',
        'https://github.com/shmakovdima',
      ]}
    />
      <Home />
    </>
)

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = context;
 
  return {
    props: {
      ...(await serverSideTranslations(locale, ['default'])),
    },
  };
};


export default HomePage