import { Container, Row, Col } from 'react-bootstrap';
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo';

import { useTranslation } from 'next-i18next';

import Title from 'components/Title';

const ProfileImage = require('images/profile.jpg');

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about">
      <Container>
        <Title title={t('home.about')} />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
              <div className="about-wrapper__image">
                <Image width={450} className="mx-auto img-fluid rounded shadow-lg" src={ProfileImage}  alt={t('home.about')} />
                <ImageJsonLd
                  images={[
                    {
                      contentUrl: 'https://www.shmakovdima.ru/og-image.jpg',
                      creator: {
                        '@type': 'Person',
                        name: 'Dmitrii Shmakov',
                      },
                      creditText: 'Dmitrii Shmakov',
                      copyrightNotice: '© Dmitrii Shmakov',
                    },
                  ]}
                />
              </div>
          </Col>
          
          <Col md={6} sm={12}>
            <div className="about-wrapper__info">
              <p className="about-wrapper__info-text">
                {t('home.paragraphOne')}
              </p>
              <p className="about-wrapper__info-text">
                {t('home.paragraphTwo')}
              </p>
              <p className="about-wrapper__info-text">
                {t('home.paragraphThree')}
              </p>
              
              <span className="d-flex about-wrapper__info-link mt-3">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn--resume"
                  href={t('home.CVLink')}
                >
                    {t('home.CV')}
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
