import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'next-i18next';
import Image from 'next/image'
import { nanoid } from 'nanoid';

import Title from 'components/Title';

const RevolutImage = require('images/revolut.png');
const MoscowJSImage = require('images/moscowjs.png');

const projects = [
  {
    id: nanoid(),
    img: RevolutImage,
    title: 'Revolut | Website',
    info: "Revolut's main website is a massive undertaking, encompassing over 80,000 pages",
    // eslint-disable-next-line max-len
    info2: 'This ambitious project utilizes a wide range of technologies such as React, Gatsby/Next.js, Redux, TypeScript, styled-components, Cypress, react-testing-library, and its own UI kit.',
    url: 'https://revolut.com',
  },
  {
    id: nanoid(),
    img: MoscowJSImage,
    title: 'MoscowJS Meetup',
    // eslint-disable-next-line max-len
    info: 'Join us for regular meetups of JavaScript developers in Moscow, where we gather to discuss frameworks, tools, and processes. These informal gatherings provide a platform for sharing knowledge and personal experiences.',
    info2: "With over 52 meetups held so far, we've built a vibrant community of JavaScript enthusiasts.",
    url: 'https://moscowjs.org',
  },
]

const Projects = () => {
  const { t } = useTranslation();

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Current projects" />
          {projects.map((project) => {
            const {
              title, info, info2, url, img, id,
            } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <div className="project-wrapper__text">
                    <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                    <div>
                      <p>
                        {info}
                      </p>
                      <p className="mb-4">{info2 || ''}</p>
                    </div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--hero"
                      href={url || '#!'}
                    >
                      {t('home.seeLive')}
                    </a>
                  </div>
                </Col>
                <Col lg={8} sm={12}>
                  <div className="project-wrapper__image">
                    <a
                      href={url || '#!'}
                      target="_blank"
                      aria-label="Project Link"
                      rel="noopener noreferrer"
                    >
                      <div data-tilt className="thumbnail rounded">
                        <Image className="mx-auto img-fluid" width={1366} src={img} alt={title} />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
