
import { Container } from 'react-bootstrap';

import Title from 'components/Title';

import { useTranslation } from 'next-i18next';

const Contact = () => {
  const { t } = useTranslation();
  
  return (
    <section id="contact">
      <Container>
        <Title title="Contact me" />
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {t('home.contactHeading')}
            </p>
            <a
              target="_blank"
              title={t('home.contactCTA')}
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={"mailto:to.dmitrii.shmakov@gmail.com"}
            >
              {t('home.contactCTA')}
            </a>
          </div>
      </Container>
    </section>
  );
};

export default Contact;
