import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useTranslation } from 'next-i18next';
import { nanoid } from 'nanoid';


const networks = [
  {
    id: nanoid(),
    name: 'facebook',
    url: 'https://www.facebook.com/shmakovdima',
  },
  {
    id: nanoid(),
    name: 'instagram',
    url: 'https://www.instagram.com/shmakovdima',
  },
  {
    id: nanoid(),
    name: 'linkedin',
    url: 'https://linkedin.com/in/shmakovdima',
  },
  {
    id: nanoid(),
    name: 'github',
    url: 'https://github.com/shmakovdima',
  },
]


const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000} title={t('home.cta')}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks
            && networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  rel="me"
                  href={url || 'https://github.com/shmakovdima'}
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          {`© ${new Date().getFullYear()} - `}
          <a href="https://github.com/shmakovdima" rel="me" target="_blank">
            Dmitrii Shmakov
          </a>
        </p>

      </Container>
    </footer>
  );
};

export default Footer;
